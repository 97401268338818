import { ArrowLeftIcon } from "@heroicons/react/outline";
import * as React from "react";
import Layout from "components/global/layout";
import Navbar from "components/global/navbar";
import PageTitle from "components/global/page-title";
import rejectedCardIcon from "images/rejected-card.svg";
import StatusPage from "../components/mercado-pago/StatusPage";
import { Link } from "gatsby";
import { useEffect, useState } from "react";

const PagoRechazado = () => {
  const [paymentId, setPaymentId] = useState()
  const [renderStatus, setRenderStatus] = useState(false)

  useEffect(() => {
    const { id, status } = JSON.parse(sessionStorage.getItem("paymentData"))
    if (status === 'rejected') setRenderStatus(true)
    setPaymentId(id)
  }, [])
  return (
    <>
      <Navbar />
      <Layout>
        <PageTitle>Vuelve a intentar</PageTitle>
        <div className="bg-gray-200 bg-opacity-30 rounded-md rounded-b-none text-primary-dark-blue px-6 py-6 my-6 flex flex-col items-center justify-center">
          <div className="rounded-full bg-primary-light-red w-36 h-36 flex items-center justify-center">
            <img
              src={rejectedCardIcon}
              alt="Tarjeta rechazada"
              className="w-12 h-12"
            />
          </div>
          <h2 className="uppercase text-center font-display font-bold text-2xl py-4 text-primary-dark-blue">
            Pago Fallido
          </h2>
          <div className="flex-col my-6">
            <Link
              to="/realizar_pago"
              className="flex text-primary-light-red w-full items-center justify-center my-4 text-base font-semibold">
              <ArrowLeftIcon className="h-4 w-4 mr-2" />
              <span>Pagar con otra tarjeta</span>
            </Link>
          </div>
        </div>
        {
          renderStatus && <StatusPage paymentId={paymentId} />
        }
      </Layout>
    </>
  );
};

export default PagoRechazado;
